@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
  --bgcolor: #111;
  --primarycolor:#6f5de7;
  --textcolor:#9f9f9f;
  --main-text-color:#379E66;
  --white-color:#fff;
}

body{ background-color: var(--bgcolor) !important;
  color: var(--textcolor) !important;
  font-family: Poppins, sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.95;
  position: relative;}
.navbar-brand { width: 280px;}
.navbar-nav .nav-link{ padding: 1rem !important; color: var(--white-color) !important; text-transform: uppercase;}

h1,
h2,
h3,
h4,
h5,
h6{font-family: Poppins, sans-serif !important; color: #f0f0f0;;}
p{font-family: Poppins, sans-serif !important; font-size: 1rem;}
.home-section{
  height: 100vh;
}
.home-section h1{
  font-size: calc(2.75rem + 1.65vw);
  font-weight: 800;
  position: relative;
  z-index: 1;
}
.home-section h1 span{
  color: var(--main-text-color);
}

.text-white{ color: var(--textcolor);}
.text-green{ color: var(--main-text-color);}
.home-outer-container{ background-image:url(../public/images/home.jpg); 
  background-color: #111;
  background-position: center center;
  height: 100vh;
  height: calc(var(--vh, 1vh)* 100);
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.home-outer-container:before{
  content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0,0,0,.4);
  }    

.animated-bar{
  background-color: var(--main-text-color);
  content: '';
    display: block;
    height: 4px;
    left: 0;
    position: relative;
    right: 0;
    width: 75px;
}
.animated-bar:before {
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: barAnimation;
  -webkit-animation-timing-function: linear;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: barAnimation;
  animation-timing-function: linear;
  background-color: #101010;
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  width: 4px;
}
.animated-bar:after{
  left: 0; 
}
.animated-bar:before {
  left: 8px;
}  

@-webkit-keyframes barAnimation {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  to {
      -webkit-transform: translateX(75px);
      transform: translateX(75px)
  }
}

@keyframes barAnimation {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  to {
      -webkit-transform: translateX(75px);
      transform: translateX(75px)
  }
}

.single-service {
  background-color: #161616;
  border-bottom: 2px solid transparent;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .2);
  margin-bottom: 30px;
  padding: 38px 42px;
  transition: all .15s ease-in-out;
}
.service-title {
  color: #f3f3f3;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -.5px;
  margin-bottom: 14px;
}
.service-icon {
  color: var(--main-text-color);
    display: inline-block;
    font-size: 5rem;
    margin-bottom: 22px;
}
.info-type {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--white-color);
}
.info-details {
  border-left: 2px solid rgba(255, 255, 255, .15);
  padding: 12px 0 12px 22px;
}
.info-icon {
  font-size: 36px;
  margin-right: 20px;
  position: relative;
  color: var(--main-text-color);
}
.contact-list li {
  display: inline-block;
}
.media {
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
}
.info-value a {
  color: var(--textcolor);
}

button:focus:not(:focus-visible){
  outline: 0px;
  box-shadow: 0 0 0 0;
}